// extracted by mini-css-extract-plugin
export var toggleDetailSlider = "component-module--toggleDetailSlider--3NxIN";
export var detailSlider = "component-module--detailSlider--14K0P";
export var dsliderAppear = "component-module--dslider-appear--24Hua";
export var hidden = "component-module--hidden--2WnsF";
export var dsliderDisappear = "component-module--dslider-disappear--JZUfR";
export var buttonContainer = "component-module--buttonContainer--1HJGZ";
export var btn = "component-module--btn--1W2Nd";
export var content = "component-module--content--27H6L";
export var dsliderLoad = "component-module--dslider-load--37xgg";
export var header = "component-module--header--3TFCI";
export var loaded = "component-module--loaded--3SUNt";