import React, { useState } from "react"
import Bonus from "../bonus";

import * as styles from "./component.module.less"

export default props => {

  const canGoBack = (props.canGoBack === undefined ? true : props.canGoBack);

  const informations = props.informations ? props.informations[props.name] : undefined;

  const [optionClicked, setOptionClicked] = useState(
      props.options.map(m => {
        if (informations !== undefined) {
          if (informations && m === "Ja") {
            return { option: m, clicked: true }
          } else if (!informations && m === "Nein") {
            return { option: m, clicked: true }
          } else if (informations === m) {
            return { option: m, clicked: true }
          } else {
            return { option: m, clicked: false }
          }
        } else {
          return { option: m, clicked: false }
        }
      })
  )

  const onHandleChange = (option, index) => {
    if (props.onHandleChange) {
      // continue with next step if choice is binary
      props.onHandleChange(props.name, option, false, props.options.length === 2);
      props.onNextStep();
    }

    setOptionClicked(
      optionClicked.map((o, i) => {
        return { option: o.option, clicked: index === i }
      })
    )
  }

  return (
    <>
      <div className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.header}>
            <div className={styles.textWrap}>
              <h4 className={styles.h4}>{props.question}</h4>
            </div>
          </div>
          <hr />
          <div className={styles.o} style={{display: "flex", flexDirection: (props.options.length > 2 && props.optionalText) ? "column" : "row"}}>
            <div
              className={styles.optionContainer}
              style={{ marginBottom: props.optionalImage ? "12rem" : "" }}
            >
              {props.options.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={styles.option}
                  >
                    <button
                      onClick={() => {
                        onHandleChange(option, index)
                      }}
                      className={`${styles.circle} ${styles.item} ${
                          optionClicked && optionClicked[index] && optionClicked[index].clicked ? styles.clicked : ""
                      } ${
                        option === "silber"
                          ? styles.silber
                          : option === "gold"
                          ? styles.gold
                          : option === "platin"
                          ? styles.platin
                          : ""
                      }
                    `}
                    >
                      {props.images && <img src={props.images[index]} alt="" />}
                      <p className={styles.text}>
                        {props.images ? "" : option}
                      </p>
                    </button>
                    <p className={styles.title}>
                      {!props.images ? "" : option}
                    </p>
                  </div>
                )
              })}
            </div>
            { props.optionalText && (
              <p style={{ lineHeight: "1.8rem" }}>{props.optionalText}</p>
            )}
          </div>
        </div>
        <img
          src={props.optionalImage}
          alt=""
          className={styles.optionalImage}
        />
        <Bonus value={props.currentBonus} size="big" />
      </div>
      <div className={styles.footer}>
        <button
          className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn} ${canGoBack === false ? styles.btnDisabled : ''}`}
          onClick={props.onPrevStep}
          disabled={canGoBack === false}
        />
        <button
          className={`btn block inverted icon icon-next icon-pos-rhs ${
            styles.btn
          } ${props.buttonDisabled ? styles.btnDisabled : ""}`}
          onClick={props.onNextStep}
          style={{ zIndex: "10" }}
          disabled={props.buttonDisabled || false}
        >
          Weiter
        </button>
      </div>
    </>
  )
}
