import React from "react"

import * as styles from "./component.module.less"

export default props => {
  const onCalcClick = props.onCalcClick || (() => {})

  return (
    <div className={styles.bonusEstimateTeaser}>
      <div className={styles.backgroundImage}>
        <div className={styles.text}>Ihre Ziele Ihr Bonus!</div>
        <div className={styles.container}>
          <div className={styles.info}>
            Stellen Sie hier Ihren Individuellen FitBonus<sup>+</sup> zusammen.
            Mit unserem BonusCoach bleiben Sie dann auch am Ball.
          </div>
          <button
            className={`btn block inverted ${styles.btn}`}
            onClick={onCalcClick}
          >
            Los Gehts
          </button>
        </div>
      </div>
    </div>
  )
}
