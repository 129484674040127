import React from "react"
import moment from "moment"
import { graphql, StaticQuery } from "gatsby"

import ContentContainer from "../../../../components/content-element/container"
import Select from "../../../../components/elements/controls/select"

import * as styles from "./component.module.less"

export default class Questionnaire extends React.Component {
  onResult

  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      complete: false,
      result: {},
    }

    this.onChange = this.onChange.bind(this)
    this.onResult = props.onResult || (() => {})
  }

  onChange(index = 1, values = []) {
    const res = this.state.result;

    if (values.find(({ name, value }) => {
      return res[name]?.value !== value
    }) === undefined) {
      return;
    }

    let s = JSON.parse(JSON.stringify(this.state))

    if (s.step < index) {
      s.step = index
    }

    values.forEach((value) => {
      s.result[value.name] = value;
    })

    const completeResult =
      Object.keys(s.result)
        .map(k => s.result[k].value)
        .filter(v => v && v !== "").length === 4

    s.complete = s.complete || completeResult

    if (s.complete && completeResult) {
      if (!this.state.complete) {
        setTimeout(() => this.onResult(s.result), 250)
      } else {
        this.onResult(s.result)
      }
    }

    this.setState(s)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query MyInsuranceFeatureQuestionnaireConfig {
            allDatoCmsMitgliedschaftsarten(
              sort: { fields: position, order: ASC }
              filter: { locale: { eq: "de" } }
            ) {
              nodes {
                id
                nameAuswahlMeineLeistungen
              }
            }

            allDatoCmsVersicherungsart(
              sort: { fields: position, order: ASC }
              filter: { locale: { eq: "de" } }
            ) {
              nodes {
                id
                nameAuswahlMeineLeistungen
              }
            }

            allDatoCmsAltersgruppe(filter: { locale: { eq: "de" } }) {
              nodes {
                id
                alterVon
                alterBis
              }
            }
          }
        `}
        render={data => {
          const customerOpts = data.allDatoCmsMitgliedschaftsarten.nodes.map(
            n => {
              return {
                value: n.id.replace(/DatoCmsMitgliedschaftsarten-/, ""),
                label: n.nameAuswahlMeineLeistungen,
              }
            }
          )

          const occupationOpts = data.allDatoCmsVersicherungsart.nodes.map(
            n => {
              return {
                value: n.id.replace(/DatoCmsVersicherungsart-/, ""),
                label: n.nameAuswahlMeineLeistungen,
              }
            }
          )

          const ageRanges = data.allDatoCmsAltersgruppe.nodes.sort((a, b) => {
            if (a.alterVon < b.alterVon) return -1
            if (a.alterVon > b.alterVon) return 1
            return 0
          })

          return (
            <section
              className={`${styles.questionnaire} ${styles.gradient}${
                this.state.complete ? " " + styles.complete : ""
              }`}
            >
              <ContentContainer className={`${styles.content}`} tag="section">
                <p className={`h1 ${styles.headline}`}>
                  Einfacher wird's nicht!
                </p>
                <h1 className={`h1 ${styles.title}`}>Jetzt Mitglied werden</h1>

                {this.state.step >= 0 && (
                  <div className={`${styles.selectWrapper} ${styles.swrap1}`}>
                    <p>Ich benötige eine Krankenversicherung für</p>
                    <Select
                      id="customer"
                      name="customer"
                      theme="light"
                      onChange={value => {
                        if (value === "") return;
                        this.onChange(1, [{
                          name: "customer",
                          value,
                        }])
                      }}
                    >
                      <option value={""}>bitte wählen...</option>
                      {customerOpts.map((opt, key) => (
                        <option key={key} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}

                {this.state.step >= 1 && (
                  <div className={styles.selectWrapper}>
                    <p>Mein Geburtstag ist</p>
                    <input
                      className={styles.dateInput}
                      type="date"
                      onChange={(e) => {
                        if ((!e.target?.valueAsNumber && e.target?.valueAsNumber !== 0) || e.target?.value?.length !== 10) {
                          return
                        }
                        const birthday = moment.utc(e.target.valueAsNumber);
                        if (birthday.year() < 1000) {
                          return
                        }

                        const age = /*now - birthday = years*/moment.utc().diff(birthday, 'years');

                        const range = ageRanges
                          .find(({ alterVon, alterBis }) => age >= alterVon && age <= alterBis);

                        this.onChange(2, [{
                          name: "age",
                          value: (range?.id.replace(/DatoCmsAltersgruppe-/, '')) || 'no',
                          alt: age,
                        }, {
                          name: "birthday",
                          value: e.target.valueAsNumber === 0 ? 1 : e.target.valueAsNumber,
                        }]);
                      }}
                    />
                  </div>
                )}

                {this.state.step >= 2 && (
                  <div className={styles.selectWrapper}>
                    <p>Aktuell (oder bald) bin ich</p>
                    <Select
                      id="occupation"
                      name="occupation"
                      theme="light"
                      onChange={value => {
                        if (value === "") return;
                        this.onChange(3, [{
                          name: "occupation",
                          value,
                        }])
                      }}
                    >
                      <option value={""}>bitte wählen...</option>
                      {occupationOpts.map((opt, key) => (
                        <option key={key} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
              </ContentContainer>
            </section>
          )
        }}
      />
    )
  }
}