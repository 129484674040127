import React from "react"
import { Link } from "gatsby"

import HtmlHead from "../../../../components/html-head";

import ContentContainer from "../../../../components/content-element/container"

import * as styles from "./component.module.less"

import * as service from "../../../../services/auth"

export default props => (
  <>
    <HtmlHead page={props.page} />

    <ContentContainer className={styles.header} tag="header">
      <Link to={"/"} className={styles.logo} data-datocms-noindex={""}>
        BKK Linde - Die gute Wahl
      </Link>
      <Link
        to={"/"}
        className={`btn outline ${styles.btn}`}
        data-datocms-noindex={""}
        onClick={() => service.logout()}
      >
        Ausloggen
      </Link>
    </ContentContainer>
  </>
)
