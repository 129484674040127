// extracted by mini-css-extract-plugin
export var calc = "component-module--calc--1TEXX";
export var light = "component-module--light--3SFUQ";
export var lbl = "component-module--lbl--24BDp";
export var resultContainer = "component-module--resultContainer---xaym";
export var result = "component-module--result--3eVYT";
export var val = "component-module--val--2lxmS";
export var msg = "component-module--msg--361LV";
export var low = "component-module--low--9iHxM";
export var ok = "component-module--ok--2WsAs";
export var high = "component-module--high--2x3qs";
export var vhigh = "component-module--vhigh--1aot-";
export var scale = "component-module--scale--2ZM7H";
export var knob = "component-module--knob--2EcDN";