import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

import LazyImg from "../../../../components/elements/lazy-img"

import * as styles from "./component.module.less"

export default props => {
  if (!props.data) {
    return null
  }

  const highlight = props.highlight === true
  const highlightType =
    highlight === false
      ? null
      : props.data.leistungsart.trim().toLowerCase() === "zusatzversicherung"
      ? styles.t2
      : styles.t1

  return (
    <StaticQuery
      query={graphql`
        query FeatureDetailPathPrefix {
          site {
            siteMetadata {
              urlPaths {
                DatoCmsLeistungsdatenbank
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {props.onClick && (
            <button
              className={styles.link}
              onClick={() =>
                props.onClick(
                  props.data.leistungsname,
                  "/" +
                    data.site.siteMetadata.urlPaths.DatoCmsLeistungsdatenbank +
                    props.data.slug +
                    "/"
                )
              }
            >
              {props.data.leistungsname}
            </button>
          )}
          {!props.onClick && (
            <Link
              className={styles.link}
              to={
                "/" +
                data.site.siteMetadata.urlPaths.DatoCmsLeistungsdatenbank +
                props.data.slug +
                "/"
              }
            >
              {props.data.leistungsname}
            </Link>
          )}

          <LazyImg
            className={`${styles.img} ${highlight ? styles.highlight : ""}`}
            src={props.data.bilderDerLeistung.fluid.src}
            gatsbyImageData={props.data.bilderDerLeistung.gatsbyImageData}
            base64={props.data.bilderDerLeistung.fluid.base64}
            alt={props.data.bilderDerLeistung.alt}
            title={props.data.bilderDerLeistung.title}
          />

          <div
            className={`${styles.details} ${
              highlight ? [styles.highlight, highlightType].join(" ") : ""
            }`}
          >
            {props.data.leistungsart && (
              <div className={styles.category}>{props.data.leistungsart}</div>
            )}
            <h2 className={`${highlight ? "h3" : "h4"} ${styles.title}`}>
              {props.data.leistungsname}
            </h2>
            {props.data.teasertext && (
              <div
                className={styles.teaser}
                dangerouslySetInnerHTML={{
                  __html: props.data.teasertext.replace(/\n/, "<br />"),
                }}
              />
            )}
            {highlight && (
              <span
                className={`btn ${highlight ? "outline" : "block"} ${
                  styles.btn
                }`}
              >
                Details zu {props.data.leistungsname}
              </span>
            )}
          </div>
        </>
      )}
    />
  )
}
