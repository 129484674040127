// extracted by mini-css-extract-plugin
export var text = "component-module--text--2Tc1l";
export var h1 = "component-module--h1--3M763";
export var h2 = "component-module--h2--m3Y9B";
export var h3 = "component-module--h3--2am6W";
export var h4 = "component-module--h4--1v-AW";
export var bonusEstimateTeaser = "component-module--bonusEstimateTeaser--3ZBM1";
export var backgroundImage = "component-module--backgroundImage--k1LmW";
export var container = "component-module--container--2RE7J";
export var info = "component-module--info--1SHli";
export var btn = "component-module--btn--qT-nt";