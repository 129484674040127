import React, { useState, useRef, Fragment } from "react"

import ContentContainer from "../../../../components/content-element/container"
import InsuranceFeatureItem from "../../../../templates/all-insurance-features/list/item"
import BonusEstimateTeaser from "../bonus_estimate_teaser"
import DetailSlider from "../../../../components/elements/detail-slider"
import * as service from "../../../../services/auth"

import * as styles from "./component.module.less"

export default props => {
  const items = props.items || []

  const [sliderConf, setSliderConf] = useState(null)
  const ref = useRef(null)
  const width = service.isBrowser() ? window.innerWidth : 0;

  const onClick = (title, url) => {
    setSliderConf(null)
    setTimeout(() => setSliderConf({ title: title, url: url }), 50)
  }

  const onClose = () => setSliderConf(null)

  const scroll = scrollOffset => {
    ref.current.scrollLeft += scrollOffset
  }

  return (
    <section className={styles.benefitSlider}>
      {items.length === 0 && <p>nichts gefunden</p>}

      {items.length > 0 && (
        <>
          <ContentContainer className={styles.headerContainer}>
            <div className={styles.header}>
              Folgende Leistungen könnten für Sie interessant sein
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.buttonLeft}
                onClick={() => {
                  scroll(-width)
                }}
              ></button>
              <button
                className={styles.buttonRight}
                onClick={() => {
                  scroll(width)
                }}
              ></button>
            </div>
          </ContentContainer>

          <ul className={styles.features} ref={ref}>
            {items.map((item, i) => {
              return (
                <Fragment key={item.slug + i.toString()}>
                  {i === 2 && (
                    <li className={styles.feature}>
                      <BonusEstimateTeaser
                        data={{
                          low: "30,00€",
                          high: "180,00€",
                        }}
                        onCalcClick={props.onCalcClick}
                      />
                    </li>
                  )}

                  <li className={styles.feature}>
                    <InsuranceFeatureItem data={item} onClick={onClick} />
                  </li>
                </Fragment>
              )
            })}
          </ul>

          {sliderConf && (
            <DetailSlider
              title={sliderConf.title}
              url={sliderConf.url}
              onClose={onClose}
            />
          )}
        </>
      )}
    </section>
  )
}
