import React, { Component } from "react"

import * as styles from "./component.module.less"

const emptyLabel = "bitte wählen"

class Select extends Component {
  value

  onChangeHandler = () => {}

  constructor(props) {
    super(props)

    if (!props.id || !props.name) {
      throw new Error(
        'invalid Select configuration, missing "id" and/or "name"'
      )
    }

    if (props.onChange) {
      this.onChangeHandler = props.onChange
    }

    this.value = props.value || props.defaultValue || ""

    this.state = {
      label: undefined,
      value: this.value,
      hasFocus: false,
    }

    this.onValueChange = this.onValueChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  reset() {
    this.value = this.props.value || ""

    this.setState(() => ({
      label: this.props.label || emptyLabel,
      value: this.value,
    }))
  }

  onValueChange(e) {
    const index = e.currentTarget.selectedIndex
    const option = e.currentTarget.getElementsByTagName("option")[index]

    this.value = option.value

    this.setState(() => ({
      label: option.textContent,
      value: this.value,
    }))

    this.onChangeHandler(this.value, option.textContent)
  }

  onBlur(e) {
    this.onValueChange(e)

    this.setState(() => ({
      hasFocus: false,
    }))
  }

  onFocus() {
    this.setState(() => ({
      hasFocus: true,
    }))
  }

  label() {
    return (
      this.state.label ||
      this.props.label ||
      this.props.defaultLabel ||
      emptyLabel
    )
  }

  render() {
    return (
      <div
        className={`${styles.select} ${this.props.error ? styles.error : ""} ${
          this.state.hasFocus ? styles.focus : ""
        }`}
      >
        <div
          className={`${styles.value} ${
            this.label() === emptyLabel ? styles.empty : ""
          }`}
        >
          {this.label()}
        </div>
        <select
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.onValueChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          ref={this.props.formElementRef || ""}
        >
          {this.props.children}
        </select>
      </div>
    )
  }
}

export default Select
