class FitBonus {
  baseUrl = ""
  apiKey = ""
  systemId = ""

  /**
   * constructor
   *
   * @param {string} baseUrl
   * @param {string} apiKey
   * @param {string} systemId
   */
  constructor(baseUrl, apiKey, systemId) {
    this.baseUrl = baseUrl
    this.apiKey = apiKey
    this.systemId = systemId
  }
    /**
   *
   *
   * @param {string}  gender
   * @param {number}  age
   * @param {boolean} vitality
   *
   * @return {Promise<>}
   */
  getBonus(gender, age, vitality) {
    const body = {
      'gender': gender,
      'age': age,
      'vitality': vitality
    }
    return new Promise((resolve, reject) => {
      fetch(new Request(this.baseUrl + '/Website/spec/get/activities',
        {
          method: 'POST',
          headers: {
            'x-apiomat-system': this.systemId,
            'x-apiomat-apikey': this.apiKey,
            'Content-Type': "application/json"
          },
          body: JSON.stringify(body)
      }))
          .then(res => res.json())
          .then(data => resolve(data))
          // catch
          .then(
              () => {},
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (e) => {
                reject(e);
              }
          )
    })
  }
}

export default FitBonus
