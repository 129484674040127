import React from "react"

import * as styles from "./component.module.less"

export default class DetailSlider extends React.Component {
  url
  timeout
  onClose

  constructor(props) {
    super(props)

    // validate url property
    if (
      props.url &&
      !props.url.includes("http:") &&
      !props.url.includes("https:")
    ) {
      this.url = props.url
    }

    this.onClose = props.onClose || (() => {})

    this.state = {
      visible: true,
      url: null,
      loaded: false,
    }

    this.onLoad = this.onLoad.bind(this)
    this.slideIn = this.slideIn.bind(this)
    this.slideOut = this.slideOut.bind(this)
  }

  componentDidMount() {
    this.slideIn()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  slideIn() {
    this.setState({
      visible: true,
    })

    clearTimeout(this.timeout)
    this.timeout = setTimeout(
      () =>
        this.setState({
          url: this.url,
          loaded: false,
        }),
      500
    )
  }

  slideOut() {
    this.setState({
      visible: false,
    })

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({
        url: null,
        loaded: false,
      })

      this.onClose()
    }, 500)
  }

  onLoad() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({
        loaded: true,
      })
    }, 600)
  }

  render() {
    return (
      <div className={styles.toggleDetailSlider}>
        <div
          className={`${styles.detailSlider} ${
            this.state.visible === false ? styles.hidden : ""
          }`}
        >
          <div className={styles.buttonContainer}>
            <button
              className={`btn outline icon icon-close-b ${styles.btn}`}
              onClick={this.slideOut}
            >
              Schliessen
            </button>
          </div>
          <div className={styles.content}>
            {this.state.url && (
              <>
                <div className={styles.header}>
                  <button
                    className={`btn outline icon icon-only icon-close-b ${styles.btn}`}
                    onClick={this.slideOut}
                  />
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <iframe
                  onLoad={this.onLoad}
                  className={this.state.loaded ? styles.loaded : ""}
                  frameBorder="0"
                  title={this.props.title || "Details"}
                  src={"https://bkk-linde.de" + this.state.url}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}
