import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import VisibilitySensor from "react-visibility-sensor"

import RemoteAssetUrl from "../../../lib/remote-asset-url"

import * as styles from "./component.module.less"

class LazyImg extends Component {
  src = ""

  constructor(props) {
    super(props)

    this.state = {
      sensorActive: true,
      imageVisible: false,
      imageLoaded: false,
    }

    this.onImageVisibilityChange = this.onImageVisibilityChange.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)

    this.src = RemoteAssetUrl(this.props.src)
  }

  onImageLoaded() {
    this.setState(() => ({
      imageLoaded: true,
    }))
  }

  onImageVisibilityChange(visible) {
    if (!visible) {
      return
    }

    this.setState(() => ({
      sensorActive: false,
      imageVisible: true,
    }))
  }

  render() {
    return (
      <VisibilitySensor
        partialVisibility={true}
        onChange={this.onImageVisibilityChange}
        active={this.state.sensorActive}
      >
        <div className={`${this.props.className || ""} ${styles.img}`}>
          {(!this.state.imageVisible || !this.state.imageLoaded) &&
            !this.props.gatsbyImageData && (
              <img
                className={styles.placeholder}
                src={this.props.base64}
                alt={this.props.alt || ""}
                title={this.props.title}
              />
            )}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          {this.props.gatsbyImageData ? (
            <GatsbyImage
              image={{
                ...this.props.gatsbyImageData,
                images: {
                  ...this.props.gatsbyImageData.images,
                  fallback: {
                    ...this.props.gatsbyImageData.images.fallback,
                    src: RemoteAssetUrl(
                      this.props.gatsbyImageData.images.fallback.src
                    ),
                    srcSet: RemoteAssetUrl(
                      this.props.gatsbyImageData.images.fallback.srcSet
                    ),
                  },
                },
              }}
              itemProp={this.props.itemProp || null}
              alt={this.props.alt || ""}
              title={this.props.title || null}
              className={`${styles.wFull} ${styles.hFull}`}
            />
          ) : (
            <img
              itemProp={this.props.itemProp || null}
              className={`${
                this.state.imageVisible && this.state.imageLoaded
                  ? ""
                  : styles.loading
              }`}
              src={this.state.imageVisible ? this.src : "#"}
              onLoad={this.onImageLoaded}
              alt={this.props.alt || ""}
              title={this.props.title || null}
            />
          )}
        </div>
      </VisibilitySensor>
    )
  }
}

export default LazyImg
