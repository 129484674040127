import React from "react"
import * as styles from "./component.module.less"
import Bonus from "../bonus"

export default props => {
  return (
    <div className={styles.inner}>
      <div className={styles.left}>
        <div className={styles.header}>
          <div className={styles.textWrap}>
            <h4 className={styles.h4}>{props.header}</h4>
          </div>
        </div>
        <hr />
        <div className={styles.selectWrapper}>{props.children}</div>
      </div>
      <Bonus value={0} className={styles.rightEnd} />
    </div>
  )
}
