import React from "react"

import * as styles from "./component.module.less";

export default props => (
    <div className={`${styles.bonus}${props.size && styles['size' + props.size] ? ' ' + styles['size' + props.size] : '' }${props.className ? ' ' + props.className : ''}`}>
      <div className={styles.wrap}>
        <h2 className={`h2 ${styles.h2}`}>Ihr Bonus:</h2>
        <h1 className={`h1 ${styles.h1}`}>
          {
              props.value
                  .toFixed(2)
                  .split(".")
                  .join(",")
          }{" €"}
        </h1>
      </div>
      {props.children}
    </div>
)
