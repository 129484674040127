// extracted by mini-css-extract-plugin
export var btnDisabled = "component-module--btnDisabled--1MAml";
export var inner = "component-module--inner--2bqw9";
export var noMargin = "component-module--noMargin--10QT0";
export var item = "component-module--item--3IkUE";
export var left = "component-module--left--2dryN";
export var header = "component-module--header--3mSa1";
export var textWrap = "component-module--textWrap--2s7Ne";
export var h4 = "component-module--h4--2SV9h";
export var selects = "component-module--selects--IPQNP";
export var selectWrap = "component-module--selectWrap--12kL4";
export var o = "component-module--o--1XUTO";
export var optionContainer = "component-module--option-container--2pBZU";
export var option = "component-module--option--3K04Q";
export var circle = "component-module--circle--3x2fM";
export var text = "component-module--text--39JCK";
export var silber = "component-module--silber--NOSoD";
export var gold = "component-module--gold--1bibX";
export var platin = "component-module--platin--3NGqY";
export var clicked = "component-module--clicked--nmqHQ";
export var title = "component-module--title--Cgm-K";
export var footer = "component-module--footer--1DUC0";
export var btn = "component-module--btn--1Ozdb";
export var optionalImage = "component-module--optional-image--1xkbv";