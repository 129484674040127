import React, { useState } from "react"

import ChooseOption from "../choose-option"
import Bonus from "../bonus"
import Calculator from "../../../../../components/elements/bmi_calculator"

import * as styles from "./component.module.less"

export default (props) => {

  function handleClick(option, index) {
    if (props.onHandleChange) {
      props.onHandleChange(props.activity.name, option.name, true);
    }

    setChecks(checks.map((m, i) => {
      if (i === index) {
        return {name: m.name, checked: !m.checked}
      } else {
        return m;
      }
    }));
  }

  const informations = props.informations ? props.informations[props.activity.name] : undefined;

  const [checks, setChecks] = useState(props.activity.subActivities ? props.activity.subActivities.map(m => {
    return {name: m.name, checked: informations ? informations.includes(m.name) : false}
  }) : []);

  switch (props.activity.name) {
    case "Zusatzversicherung":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Betriebliche Gesundheitsförderung":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Vitality":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          name={props.activity.name}
          options={["silber", "gold", "platin"]}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Impfungen":
      return (
        <>
          <div className={styles.inner}>
            <div className={styles.left}>
              <div className={styles.header}>
                <div className={styles.textWrap}>
                  <h4 className={styles.h4}>
                    {props.activity.websiteDescription}
                  </h4>
                </div>
              </div>
              <hr />
              <div
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: "37px",
                  display: "flex",
                }}
              >
                {checks.map((m, index) => {
                  return (
                      <button
                        className={styles.checkboxVac}
                        key={index}
                        onClick={() => {handleClick(m, index)}}
                      >
                        <input
                          type="checkbox"
                          value={m.name}
                          className={styles.checkboxV}
                          checked={m.checked}
                          readOnly
                        />
                        {m.name}
                      </button>
                  )
                })}
              </div>
            </div>
            <Bonus value={props.currentBonus} size="big" />
          </div>
          <div className={styles.footer}>
            <button
              className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn}`}
              onClick={props.handlePrevStep}
            />
            <button
              className={`btn block inverted icon icon-next icon-pos-rhs ${styles.btn}`}
              onClick={props.handleNextStep}
            >
              Weiter
            </button>
          </div>
        </>
      )
    case "Eigenfinanzierte Vorsorge":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          name={props.activity.name}
          options={["Ja", "Nein"]}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Zahnvorsorge":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Schwangerschaftsvorsorge":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Krebsvorsorge":
      return (
        <>
          <div className={styles.inner}>
            <div className={styles.left}>
              <div className={styles.header}>
                <div className={styles.textWrap}>
                  <h4 className={styles.h4}>
                    {props.activity.websiteDescription}
                  </h4>
                </div>
              </div>
              <hr />
              <div
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: "3rem",
                  display: "flex",
                }}
              >
                {checks.map((m, index) => {
                  return (
                      <button
                        className={styles.checkboxVac}
                        key={index}
                        onClick={() => {handleClick(m, index)}}
                      >
                        <input
                          type="checkbox"
                          value={m.name}
                          className={styles.checkboxV}
                          checked={m.checked}
                          readOnly
                        />
                        {m.name}
                      </button>
                  )
                })}
              </div>
            </div>
            <Bonus value={props.currentBonus} size="big" />
          </div>
          <div className={styles.footer}>
            <button
              className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn}`}
              onClick={props.handlePrevStep}
            />
            <button
              className={`btn block inverted icon icon-next icon-pos-rhs ${styles.btn}`}
              onClick={props.handleNextStep}
            >
              Weiter
            </button>
          </div>
        </>
      )
    case "Nichtraucher":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "BMI":
      return (
        <>
          <div className={styles.inner}>
            <div className={styles.left}>
              <div className={styles.header}>
                <div className={styles.textWrap}>
                  <h4 className={styles.h4}>
                    {props.activity.websiteDescription}
                  </h4>
                </div>
              </div>
              <hr />
              <div className={styles.bmiContainer}>
                <input type="checkbox" className={styles.checkboxBMI} />
                {props.activity.generalDescription}
              </div>
              <div
                className={styles.optionContainer}
                style={{ marginTop: "1rem" }}
              >
                <Calculator scale light onChangeBmi={props.onChangeBmi}/>
              </div>
            </div>
            <Bonus value={props.currentBonus} size="big" />
          </div>
          <div className={styles.footer}>
            <button
              className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn}`}
              onClick={props.handlePrevStep}
            />
            <button
              className={`btn block inverted icon icon-next icon-pos-rhs ${styles.btn}`}
              onClick={props.handleNextStep}
            >
              Weiter
            </button>
          </div>
        </>
      )
    case "Sportaktivitäten":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Prävention":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Zahnvorsorge Jugendliche":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Babyschwimmen":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "Jugenduntersuchung":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    case "U-Untersuchungen":

      return (
        <>
          <div className={styles.inner}>
            <div className={styles.left}>
              <div className={styles.header}>
                <div className={styles.textWrap}>
                  <h4 className={styles.h4}>
                    {props.activity.websiteDescription}
                  </h4>
                </div>
              </div>
              <hr />
              <div
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: "3rem",
                  display: "flex",
                }}
              >
                {checks.map((m, index) => {
                  return (
                      <button
                        className={styles.checkboxVac}
                        key={index}
                        onClick={() => {handleClick(m, index)}}
                      >
                        <input
                          type="checkbox"
                          value={m.name}
                          className={styles.checkboxV}
                          checked={m.checked}
                          readOnly
                        />
                        {m.name}
                      </button>
                  )
                })}
              </div>
            </div>
            <Bonus value={props.currentBonus} size="big" />
          </div>
          <div className={styles.footer}>
            <button
              className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn}`}
              onClick={props.handlePrevStep}
            />
            <button
              className={`btn block inverted icon icon-next icon-pos-rhs ${styles.btn}`}
              onClick={props.handleNextStep}
            >
              Weiter
            </button>
          </div>
        </>
      )
    case "Check-Up 35":
      return (
        <ChooseOption
          onPrevStep={props.handlePrevStep}
          onNextStep={props.handleNextStep}
          question={props.activity.websiteDescription}
          options={["Ja", "Nein"]}
          name={props.activity.name}
          optionalText={props.activity.generalDescription}
          onHandleChange={props.onHandleChange}
          currentBonus={props.currentBonus}
          informations={props.informations}
        />
      )
    default:
      return null
  }
}
