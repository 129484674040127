import React, {useEffect, useState} from "react"

import * as styles from './component.module.less'

const okLow = 19;
const okHigh = 27;

export default props => {

  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [bmi, setBmi] = useState(0);
  const [bmiPercentage, setBmiPercentage] = useState(0);

  const [category, setCategory] = useState(null);

  const onChange = props.onChangeBmi || (() => {});

  useEffect(() => {
    if (height <= 0 || weight <= 0) {
      setCategory(null);
      return;
    }

    const b = Math.round(weight / Math.pow(height / 100, 2));
    setBmi(b);
    onChange(b, b >= okLow && b <= okHigh);

    // 0 - 18
    if (b < okLow) {
      setBmiPercentage(Math.round(b / (okLow - 1) * 25));

      setCategory({
        style: styles.low,
        prefix: 'Sie haben',
        name: 'Untergewicht',
      });
      return;
    }
    // 19 - 27
    if (b <= okHigh) {
      setBmiPercentage(Math.round(25 + ((b - okLow) / (okHigh-okLow) * 25)));

      setCategory({
        style: styles.ok,
        prefix: 'Prima, Sie haben',
        name: 'Normalgewicht',
      });
      return;
    }
    // 28 - 32
    if (b <= 32) {
      setBmiPercentage(Math.round(50 + ((b - 28) / (32-28) * 25)));

      setCategory({
        style: styles.high,
        prefix: 'Sie haben',
        name: 'Übergewicht',
      });
      return;
    }

    // 33 - 35
    const bp = Math.round(75 + ((b - 33) / (35-33) * 25));
    setBmiPercentage(bp > 100 ? 100 : bp);

    setCategory({
      style: styles.vhigh,
      prefix: 'Sie leiden an',
      name: 'Fettleibigkeit',
    });

  }, [height, weight]);

  return (
    <div className={`${styles.calc}${props.className ? ' ' + props.className : ''}${props.light === true ? ' ' + styles.light : ''}`}>
      <form>
        <div className={styles.lbl}>Berechnen Sie ihren BMI</div>
        <input type="number" placeholder="Körpergröße in cm" onInput={e => setHeight(e.target.value)} />
        <input type="number" placeholder="Gewicht in kg" onInput={e => setWeight(e.target.value)} />
      </form>

      <div className={styles.resultContainer}>
        <div className={`${styles.result}${category ? ' ' + category.style : ''}`}>
          <span className={styles.val}>{bmi}</span>
          {category &&
              <span className={styles.msg}>
                {category.prefix}<br />
                <strong>{category.name}</strong>
              </span>
          }
        </div>
      </div>

      {props.scale &&
          <div className={styles.scale}>
            {bmiPercentage > 0 &&
                <span className={styles.knob} style={{
                  transform: 'translateX(' + bmiPercentage + '%)'
                }} />
            }
            <div className={styles.low} data-lbl="18" />
            <div className={styles.ok} data-lbl="27" />
            <div className={styles.high} data-lbl="32" />
            <div className={styles.vhigh} />
          </div>
      }
    </div>
  )
}
