// extracted by mini-css-extract-plugin
export var link = "component-module--link--35p7c";
export var img = "component-module--img--3_opu";
export var details = "component-module--details--Trtxm";
export var title = "component-module--title--8Sy-o";
export var btn = "component-module--btn--rE0eC";
export var category = "component-module--category--si1U0";
export var teaser = "component-module--teaser--3vUyt";
export var highlight = "component-module--highlight--1L-nU";
export var t1 = "component-module--t1--2o3Uq";
export var t2 = "component-module--t2--H7Vsg";